/* Tabla dashboard*/
.tabla-cabecera-dashboard{
  background-color: #73b72b ;
  color: white;
  text-align: left;
  font-size: 24px;
  padding:.75rem 1.25rem;
  /*margin-bottom:0;background-color:rgba(0,0,0,.03);border-bottom:1px solid rgba(0,0,0,.125)*/
}

.encabezado  {
  background-color: #73B72B; /* Color de fondo para el encabezado */
  color: white; /* Color del texto en el encabezado */
  /* font-weight: 400 px;  Estilo de fuente en el encabezado */
  text-align: left; /* Alineación del texto en el encabezado */
  font-size: 15px;
}
a{
  font-size: 12px;
}

.texto{
  font-size: 20px;
}
.letra-cabecera{
  color: white;
}

.custom-card-table{
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50vh;
}


.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}
