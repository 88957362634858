/* Fonts */


/*@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');*/


/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300&display=swap');*/

//Opcion1@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600&family=Crimson+Text&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&family=Shippori+Mincho&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
//@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@400;500;600&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');