/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
h1,
h2,
h3,
h4,
h5,
th,
td,
p,
tbody,
input,
select,
option,
button {
    font-family: Mulish;
} 

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import './assets/styles/variables';
@import "compass-mixins/lib/compass";
@import "compass-mixins/lib/animate";
@import "bootstrap/scss/bootstrap";
@import "mdi/scss/materialdesignicons";
//@import "~font-awesome/scss/font-awesome";
@import "flag-icon-css/sass/flag-icon";
@import "simple-line-icons/scss/simple-line-icons";
//@import "~typicons.font/src/font/typicons.css";
@import "chartist/dist/chartist.min.css";
@import "./assets/styles/components/loaders/loaders";
@import "./assets/styles/fonts";
@import "./assets/styles/mixins/accordions";
@import"./assets/styles/mixins/text";
@import "./assets/styles/mixins/functions";
@import "./assets/styles/mixins/background";
@import "./assets/styles/mixins/animation";
@import "./assets/styles/mixins/badges";
@import "./assets/styles/mixins/breadcrumbs";
@import "./assets/styles/mixins/pagination";
@import "./assets/styles/mixins/buttons";
@import "./assets/styles/mixins/misc";
@import "./assets/styles/mixins/color-functions";
@import "./assets/styles/mixins/cards";
@import "./assets/styles/mixins/tooltips";
@import "./assets/styles/mixins/popovers";
@import "./assets/styles/mixins/blockqoute";
@import "./assets/styles/components/accordions";
@import "./assets/styles/components/breadcrumbs";
@import "./assets/styles/components/forms";
@import "./assets/styles/components/icons";
@import "./assets/styles/components/tables";
@import "./assets/styles/components/buttons";
@import "./assets/styles/components/badges";
@import "./assets/styles/components/cards";
@import "./assets/styles/components/checkbox-radio";
@import "./assets/styles/typography";
@import "./assets/styles/components/preview";
@import "./assets/styles/components/lists";
@import "./assets/styles/components/bootstrap-progress";
@import "./assets/styles/components/bootstrap-alerts";
@import "./assets/styles/components/tabs";
@import "./assets/styles/components/dropdowns";
@import "./assets/styles/components/pagination";
@import "./assets/styles/components/popovers";
@import "./assets/styles/components/modals";
@import "./assets/styles/components/nav";
@import "./assets/styles/components/timeline";
@import "./assets/styles/reset";
@import "./assets/styles/components/rating";
@import "./assets/styles/components/tooltips";
@import "./assets/styles/components/dashboard_tabla";
@import "./assets/styles/components/plugin-overrides/chartjs";
@import "./assets/styles/responsive";
@import "./assets/styles/misc";
@import "./assets/styles/utilities";
@import "./assets/styles/components/todo-list";
@import "./assets/styles/auth";
@import "./assets/styles/demo";
@import "./assets/styles/misc";
@import "./assets/styles/layouts";
@import 'ngx-toastr/toastr.css';
@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';

@import 'bootstrap/dist/css/bootstrap.min.css';

@import "ng-wizard/themes/ng_wizard";
@import "ng-wizard/themes/ng_wizard.min.css";
@import "ng-wizard/themes/ng_wizard_theme_arrows";

@import "ng-wizard/themes/ng_wizard_theme_arrows.min.css";
@import "ng-wizard/themes/ng_wizard_theme_circles";
@import "ng-wizard/themes/ng_wizard_theme_circles.min.css";
@import "ng-wizard/themes/ng_wizard_theme_dots";
@import "ng-wizard/themes/ng_wizard_theme_dots.min.css";

@import 'bootstrap/dist/css/bootstrap.min.css';



@import "ng-wizard-v2/themes/ng_wizard.css";
@import "ng-wizard-v2/themes/ng_wizard_theme_arrows.css";
@import "ng-wizard-v2/themes/ng_wizard_theme_circles.css";
@import "ng-wizard-v2/themes/ng_wizard_theme_dots.css";

ngb-modal-backdrop {
    z-index: 999 !important;
  }
  
  ngb-modal-window {
    z-index: 999 !important;
  }
  
  // Si el joyride está utilizando un z-index específico en algún otro lugar y necesitas que sea mayor
  .joyride-step__holder,
  .backdrop-container {
    z-index: 1000 !important; // O cualquier valor mayor que 999 si es necesario
  }
  /* Personalizar el color de fondo y el texto de los botones */
.joyride-tooltip__button--next,
.joyride-tooltip__button--back {
    background-color: #4CAF50; /* Verde */
    color: white;
}

/* Personalizar el color de fondo y el texto del botón de cierre */
.joyride-tooltip__close {
    color: #4CAF50; /* Verde */
}

/* Personalizar la leyenda del tooltip */
.joyride-button {
    color: #4CAF50; /* Verde */
}


bs-month-calendar-view .bs-datepicker-head,
bs-month-calendar-view .bs-datepicker-navigation-view {
    background-color: #73b72b !important;
}

/* Para el pseudo-elemento ::before que requiere redondeo */
table.months tr td span.selected::before {
  border-radius: 50px !important; /* Asumiendo que deseas bordes redondeados */
}




/* Asumiendo que los elementos span.selected deben tener un fondo verde */
table.months tr td span.selected {
  background-color: rgb(115, 183, 43) !important;
}
.small-text {
  font-size: 12px !important;
}

.joyride-step__container {
  max-width: 470px !important;
  width: 470px !important; 
  height: auto !important; 
}

.joyride-step__container .joyride-step__body {
  font-size: 13px !important; 
}

.joyride-step__container .joyride-step__header {
  font-size: 14px !important; 
}

.joyride-step__title {
  font-weight: 700;
  font-size: 16px !important;
}
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
/* Skeleton loading style. this CSS code implement to many styles components. 
Read: https://www.freecodecamp.org/news/how-to-build-skeleton-screens-using-css-for-better-user-experience/  
*/
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

/* HTML: <div class="loader"></div> */
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Asegura que esté por encima del contenido */
}


.loader-circule {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #73b72b 90%, #0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
    33% { background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%; }
    50% { background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%; }
    66% { background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%; }
}


/* HTML: <div class="loader"></div> */
.loader-graph-pastel {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #73b72b ;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}